import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import tinyColor from 'tinycolor2';
import withAppSettings from './with-app-settings';
import getDisplayName from '../services/get-display-name';

// eslint-disable-next-line max-params
export default function withSettingsColor(path, propName, siteColor, alpha = 1, fallback) {
  return WrappedComponent => {
    class Wrapper extends Component {
      static displayName = `withSettingsColor(${getDisplayName(WrappedComponent)})`;
      static propTypes = {
        color: PropTypes.object,
        siteColors: PropTypes.object,
      };

      render() {
        const { color = {}, siteColors, ...otherProps } = this.props;
        const colorCode = color.value || siteColors[siteColor] || fallback;
        const props = {
          [propName]: colorCode
            ? tinyColor(colorCode)
                .setAlpha(alpha)
                .toRgbString()
            : '',
          ...otherProps,
        };

        return <WrappedComponent {...props} />;
      }
    }

    hoistNonReactStatics(Wrapper, WrappedComponent);

    return withAppSettings({ color: path })(Wrapper);
  };
}
