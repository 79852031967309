import {
  SECTION_HOMEPAGE,
  SECTION_CATEGORY,
  SECTION_POST_LIST,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { isInFeed, isInCategory } from '../services/detect-route';
import { getRoute } from '../router/router-selectors';
import { getBundleName } from '../store/app-config/app-config-selectors';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../../post-list-widget/constants/post-list-widget';

export const getSection = (state, fallbackEnabled) => {
  const route = getRoute(state);
  if (isInFeed(route)) {
    return SECTION_HOMEPAGE;
  }

  if (isInCategory(route)) {
    return SECTION_CATEGORY;
  }

  if (getBundleName(state) === POST_LIST_WIDGET_BUNDLE_NAME) {
    return SECTION_POST_LIST;
  }

  return fallbackEnabled ? SECTION_CATEGORY : undefined;
};
