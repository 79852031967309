import PropTypes from 'prop-types';
import React from 'react';

import Counter from '../counter';
import styles from './counter-compact.scss';

const CounterCompact = ({ children, dataHook, ...counterProps }) => (
  <span className={styles.container} data-hook={dataHook}>
    {children}
    <Counter {...counterProps} className={styles.count} />
  </span>
);

CounterCompact.propTypes = {
  children: PropTypes.node,
  dataHook: PropTypes.string,
};

export default CounterCompact;
