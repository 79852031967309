import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import withTranslate from '../../hoc/with-translate';
import {
  getIsTagLabelEnabled,
  getTagAlignment,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
} from '../../selectors/app-settings-selectors.js';
import { getTagTextAlignment } from '../../services/tag-helpers';
import Tag from './tag';
import styles from './post-tags.scss';

export class PostTags extends React.Component {
  render() {
    const { t, tags, isTagLabelEnabled, tagAlignment, tagVerticalSpacing, tagHorizontalSpacing } = this.props;

    if (!tags || !tags.length) {
      return null;
    }

    const containerStyles = {
      textAlign: getTagTextAlignment(tagAlignment),
    };

    const tagWrapperStyles = {
      marginLeft: -tagHorizontalSpacing,
      marginTop: -tagVerticalSpacing,
    };

    // todo update slug when tags page is done
    return (
      <div style={containerStyles} className={styles.container}>
        {isTagLabelEnabled && <p className={styles.tagLabel}>{t('post-page.tags-label')}</p>}
        <div style={tagWrapperStyles}>
          {tags.map(({ id, slug, label }) => (
            <Tag key={id} slug={slug} label={label} />
          ))}
        </div>
      </div>
    );
  }
}

PostTags.propTypes = {
  t: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  isTagLabelEnabled: PropTypes.bool.isRequired,
  tagAlignment: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
};

const mapRuntimeToProps = state => ({
  isTagLabelEnabled: getIsTagLabelEnabled(state),
  tagAlignment: getTagAlignment(state),
  tagVerticalSpacing: getTagVerticalSpacing(state),
  tagHorizontalSpacing: getTagHorizontalSpacing(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(PostTags);
