import {
  getPostCover as _getPostCover,
  getPostVideoEmbedCover as _getPostVideoEmbedCover,
} from '@wix/communities-universal/dist/src/services/get-post-cover';
import { resizeThumbnail } from './image-utils';
import { getVideoUrl } from './video-utils';

export { getPostImageCover } from '@wix/communities-universal/dist/src/services/get-post-cover';

const helpers = { resizeThumbnail, getVideoUrl };

export const getPostCover = post => _getPostCover(post, helpers);
export const getPostVideoEmbedCover = post => _getPostVideoEmbedCover(post, helpers);
