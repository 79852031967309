import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CommentCount from '../comment-count';
import CommentCountCompact from '../comment-count-compact';
import WriteComment from '../write-comment';
import withExperiment from '../../../common/hoc/with-experiment';
import { EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR } from '@wix/communities-blog-experiments';

export const CommentCountOrNew = ({ displayIcons, isMapToPGEnabled, ...rest }) => {
  if (!isMapToPGEnabled && !rest.count) {
    return <WriteComment {...rest.link} />;
  }

  const Count = displayIcons ? CommentCountCompact : CommentCount;
  return <Count showZero={isMapToPGEnabled} {...omit(rest, 'className')} />;
};

CommentCountOrNew.propTypes = {
  link: PropTypes.object.isRequired,
  count: PropTypes.number,
  displayIcons: PropTypes.bool,
  isMapToPGEnabled: PropTypes.bool,
};

export default withExperiment({
  isMapToPGEnabled: EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR,
})(CommentCountOrNew);
