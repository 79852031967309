import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../runtime-context';
import {
  getTagLayout,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
} from '../../../selectors/app-settings-selectors.js';
import { isTagLayoutButton } from '@wix/communities-blog-client-common/dist/src/constants/tag-constants';
import styles from './tag.scss';

class Tag extends React.Component {
  render() {
    const { slug, label, tagLayout, tagVerticalSpacing, tagHorizontalSpacing } = this.props;

    const tagLayoutButton = isTagLayoutButton(tagLayout);

    const tagStyles = {
      marginLeft: tagHorizontalSpacing,
      marginTop: tagVerticalSpacing,
    };

    const tagClassName = classNames(
      styles.tag,
      tagLayoutButton && styles.tagButton,
      styles.blogPostTagsFont,
      styles.blogPostTagsColor,
      styles.blogPostTagsBorder,
      tagLayoutButton && styles.blogPostTagsBackground,
      tagLayoutButton && styles.blogPostTagsBorderColor,
      tagLayoutButton && styles.blogPostTagsPadding,
    );

    return (
      <a href={slug} style={tagStyles} className={tagClassName}>
        {label}
      </a>
    );
  }
}

Tag.propTypes = {
  slug: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tagLayout: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
};

const mapRuntimeToProps = state => ({
  tagLayout: getTagLayout(state),
  tagVerticalSpacing: getTagVerticalSpacing(state),
  tagHorizontalSpacing: getTagHorizontalSpacing(state),
});

export default connect(mapRuntimeToProps)(Tag);
