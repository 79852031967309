import { TAG_ALIGNMENTS } from '@wix/communities-blog-client-common/dist/src/constants/tag-constants';

export const getTagTextAlignment = tagAlignment => {
  switch (tagAlignment) {
    case TAG_ALIGNMENTS.left:
      return 'left';
    case TAG_ALIGNMENTS.center:
      return 'center';
    case TAG_ALIGNMENTS.right:
      return 'right';
    default:
      return 'left';
  }
};
