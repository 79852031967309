export const ROUTE_404 = '/404';
export const ROUTE_CREATE_POST = '/create-post';
export const ROUTE_POST = '/:postSlug';
export const ROUTE_POST_ADVANCED_SLUG = '/:year/:month(/:day)/:postSlug(/)';
export const ROUTE_EDIT_POST = '/:postSlug/edit';
export const ROUTE_LAST_POST = '/';

export const ROUTE_LOGIN = '/login';
export const ROUTE_ACCOUNT_SUSPENDED = '/account-suspended';

export const ROUTE_PREVIEW_POST = '/:postSlug/preview/:instance';
export const ROUTE_EDIT_COMMENT = '/:postSlug/edit-comment/:commentId';
export const ROUTE_EDIT_COMMENT_ADVANCED_SLUG = '/:year/:month(/:day)/:postSlug/edit-comment/:commentId';
