import PropTypes from 'prop-types';
import React from 'react';

import Highlighter from '../highlighter';
import { getContentText } from '../../services/content-text';
import styles from './simple-renderer.scss';

const Renderer = ({ contentState, excerpt, query, maxLength }) => {
  const text = excerpt ? excerpt.slice(0, maxLength) : getContentText(contentState).slice(0, maxLength);
  return (
    <div className={styles.content} data-hook="post-description">
      <p className={styles.text}>{query ? <Highlighter text={text} query={query} /> : text}</p>
    </div>
  );
};

Renderer.propTypes = {
  contentState: PropTypes.object,
  excerpt: PropTypes.string,
  query: PropTypes.string,
  maxLength: PropTypes.number,
};

export default Renderer;
