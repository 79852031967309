import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import styles from './unpublished-changes-badge.scss';

const UnpublishedChangesBadge = ({ t, className, borderClassName }) => (
  <span className={classNames(styles.container, className, borderClassName)} data-hook="unpublished-changes-badge">
    {t('unpublished-changes-badge.label')}
  </span>
);

UnpublishedChangesBadge.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  borderClassName: PropTypes.string,
};

export default withTranslate(UnpublishedChangesBadge);
