import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';
import {
  getIsViewCountEnabled,
  getIsCommentCountEnabled,
  getIsLikeCountEnabled,
  getIsAuthorNameEnabled,
  getIsPostUpdatedDateEnabled,
  getIsCommentsEnabled,
  getIsRecentPostsEnabled,
  getIsCategoryLabelsEnabled,
  getIsPostTitleEnabled,
  getIsPostPublishDateEnabled,
  getIsReadingTimeEnabled,
  getIsPostDescriptionEnabled,
  getAuthorInfoType,
  getIsAuthorPictureEnabled,
  getIsAuthorBadgeEnabled,
  getIsMoreOptionsMenuEnabled,
} from '../selectors/app-settings-selectors';
import { AUTHOR_INFO_TYPE_NAME } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { getIsPostInPreview } from '../selectors/is-post-in-preview';
import withHocName from './with-hoc-name';

const mapRuntimeToProps = (state, ownProps) => {
  const isPostInPreview = getIsPostInPreview(state);
  const showComments = !isPostInPreview && getIsCommentsEnabled(state);
  const showRecentPosts = !isPostInPreview && getIsRecentPostsEnabled(state);
  const showViewCount = !isPostInPreview && getIsViewCountEnabled(state);
  const showCommentCount = showComments && getIsCommentCountEnabled(state);
  const showLikeCount = !isPostInPreview && getIsLikeCountEnabled(state);
  const showAuthorName = getIsAuthorNameEnabled(state);
  const authorInfoType = getAuthorInfoType(state);
  const showPostUpdatedDate = getIsPostUpdatedDateEnabled(state);
  const showCategoryLabels = getIsCategoryLabelsEnabled(state);
  const showPostTitle = getIsPostTitleEnabled(state);
  const showPublishDate = getIsPostPublishDateEnabled(state);
  const showReadingTime = getIsReadingTimeEnabled(state);
  const showPostDescription = getIsPostDescriptionEnabled(state);
  const oldShowAuthorPictureValue = authorInfoType !== AUTHOR_INFO_TYPE_NAME;
  const showAuthorPicture = getIsAuthorPictureEnabled(state, oldShowAuthorPictureValue);
  const showAuthorBadge = !ownProps.hideAuthorBadge && getIsAuthorBadgeEnabled(state);
  const showMoreOptionsMenu = getIsMoreOptionsMenuEnabled(state);

  return {
    showComments,
    showRecentPosts,
    showViewCount,
    showCommentCount,
    showLikeCount,
    showAuthorName,
    showAuthorPicture,
    showAuthorBadge,
    authorInfoType,
    showPostUpdatedDate,
    showCategoryLabels,
    showPostTitle,
    showPublishDate,
    showReadingTime,
    showPostDescription,
    showMoreOptionsMenu,
    isMetadataFooterVisible: !isPostInPreview && (showViewCount || showCommentCount || showLikeCount),
    isMetadataHeaderVisible: showAuthorName || showPublishDate || showReadingTime,
  };
};

export default flowRight(connect(mapRuntimeToProps), withHocName('WithFeedMetadataSettings'));
