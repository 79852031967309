import { get, find } from 'lodash';
import tinyColor from 'tinycolor2';

const throwRequired = name => {
  throw new Error(`${name} is required`);
};

const getStyleColor = (appSettings, wixParam) => get(appSettings, `style.colors[${wixParam}].value`);

export const getPresetColor = (appSettings, presetName) =>
  get(
    find(appSettings.colorPresets, ({ reference }) => reference === presetName),
    'value',
  );

export const setColorOpacity = (color, alpha = 1) =>
  tinyColor(color)
    .setAlpha(alpha)
    .toRgbString();

export const getSiteColor = (appSettings, { wixParam, presetFallback, colorFallback, opacity = undefined }) => {
  if (!wixParam) throwRequired('wixParam');
  if (!presetFallback) throwRequired('presetFallback');
  if (!colorFallback) throwRequired('colorFallback');
  const color = getStyleColor(appSettings, wixParam) || getPresetColor(appSettings, presetFallback) || colorFallback;
  return opacity !== undefined ? setColorOpacity(color, opacity) : tinyColor(color).toRgbString();
};
