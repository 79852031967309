import { connect } from '../components/runtime-context';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';

export default function withDeviceType(WrappedComponent) {
  const mapRuntimeToProps = state => {
    const isMobile = getIsMobile(state);
    return {
      isMobile,
      isDesktop: !isMobile,
    };
  };

  return connect(mapRuntimeToProps)(WrappedComponent);
}
