import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import { getIsPostPageBundle } from '../store/app-config/app-config-selectors';
import withHocName from './with-hoc-name';

const getFontClassName = property => `blog-post-${property}-font`;
const getFontClassNameWithStyle = property => `blog-post-${property}-style-font`;

const mapRuntimeToProps = state => ({
  titleFontClassName: getFontClassName('title'),
  contentFontClassName: getFontClassName(getIsPostPageBundle(state) ? 'page' : 'description'),
  contentFontClassNameWithStyle: getFontClassNameWithStyle('description'),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithFontClassName')
);
