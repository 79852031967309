import { getPost } from '../selectors/post-selectors';
import { permissionsChecker } from '@wix/communities-blog-universal/dist/src/permissions-checker';
import createPermissionFlags from './create-permission-flags';

export default function createPermissionChecker(state, user, permissionFlags = null) {
  const flags = permissionFlags || createPermissionFlags(state);

  return permissionsChecker(flags, user, resource => {
    if (resource.postId) {
      resource = getPost(state, resource.postId);
    }
    return resource;
  });
}
