import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';

import ViewCount from '../view-count';
import ViewCountCompact from '../view-count-compact';
import CommentCountOrNew from '../../../comments/components/comment-count-or-new';
import { getViewCount, getCommentCount } from '../../store/post-counters/post-counters-selectors';
import styles from './post-stats.scss';

const PostStats = ({
  post: { isCommentsDisabled },
  displayIcons,
  postLink,
  className,
  showViewCount,
  showCommentCount,
  commentClassName,
  viewCount,
  totalComments,
}) => {
  const Views = displayIcons ? ViewCountCompact : ViewCount;
  const showCommentStats = showCommentCount && (totalComments > 0 || !isCommentsDisabled);
  return (
    <div className={classNames(styles.container, 'post-stats', className)} data-hook="post-stats">
      {showViewCount && <Views count={viewCount} className={className} />}
      {showCommentStats && (
        <CommentCountOrNew
          displayIcons={displayIcons}
          count={totalComments}
          link={{
            to: postLink,
            className: classNames(styles.comments, commentClassName),
            addHoverClasses: false,
          }}
          className={className}
        />
      )}
    </div>
  );
};

PostStats.propTypes = {
  post: PropTypes.object.isRequired,
  displayIcons: PropTypes.bool,
  postLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  commentClassName: PropTypes.string,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
};

PostStats.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
};

const mapRuntimeToProps = (state, { post }) => {
  return {
    viewCount: getViewCount(state, post._id),
    totalComments: getCommentCount(state, post._id),
  };
};

export default connect(mapRuntimeToProps)(PostStats);
