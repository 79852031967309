import React from 'react';
import { get } from 'lodash';
import { USER_PRIVACY_STATUS_PRIVATE } from '../constants/user-privacy-status';
import NoopLink from '../components/link/noop-link';

const forUserPrivacyType = (PublicUserComponent, PrivateUserComponent = NoopLink) => {
  return props => {
    return get(props, 'user.privacyStatus') === USER_PRIVACY_STATUS_PRIVATE ? (
      <PrivateUserComponent {...props} />
    ) : (
      <PublicUserComponent {...props} />
    );
  };
};

export default forUserPrivacyType;
