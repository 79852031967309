import React from 'react';

export const DeleteIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    viewBox="0 0 13 14"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M4 10h1V5H4v5zm4 0h1V5H8v5zm-2 0h1V5H6v5zm7-8H9v-.5A1.6 1.6 0 0 0 7.392 0H5.525A1.514 1.514 0 0 0 4 1.5V2H0v1h1v8.5A2.5 2.5 0 0 0 3.5 14h6a2.5 2.5 0 0 0 2.5-2.5V3h1V2zm-8-.5a.52.52 0 0 1 .525-.5h1.867A.61.61 0 0 1 8 1.5V2H5v-.5zm6 10A1.5 1.5 0 0 1 9.5 13h-6A1.5 1.5 0 0 1 2 11.5V3h9v8.5z" />
  </svg>
);
