import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { AvatarPlaceholder } from '../icons/avatar-placeholder';
import styles from './fluid-avatar-image.scss';

const getStyle = image => (image ? { backgroundImage: `url(${image})` } : {});

const FluidAvatarImage = ({ image, ariaLabel }) => (
  <div
    aria-label={ariaLabel}
    className={classNames(styles.container, 'fluid-avatar-image')}
    style={getStyle(image)}
    data-hook="avatar-image"
  >
    {image ? null : <AvatarPlaceholder className={styles.placeholder} />}
  </div>
);

FluidAvatarImage.propTypes = {
  image: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default FluidAvatarImage;
