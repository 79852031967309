export const HOVERING_BEHAVIOUR = {
  APPEARS: 'APPEARS',
  NEVER_SHOW: 'NEVER_SHOW',
  NO_CHANGE: 'NO_CHANGE',
  DISAPPEARS: 'DISAPPEARS',
};

export const OPTION_GALLERY_SIZE_TYPE = {
  RATIO: 'ratio',
  PX: 'px',
  SMART: 'smart',
};

export const VIDEO_PLAY = {
  HOVER: 'hover',
  AUTO: 'auto',
  ON_CLICK: 'onClick',
};

export const OVERLAY_ANIMATION = {
  NO_EFFECT: 'NO_EFFECT',
  FADE_IN: 'FADE_IN',
  EXPAND: 'EXPAND',
  SLIDE_UP: 'SLIDE_UP',
  SLIDE_RIGHT: 'SLIDE_RIGHT',
};

export const IMAGE_HOVER_ANIMATION = {
  NO_EFFECT: 'NO_EFFECT',
  ZOOM_IN: 'ZOOM_IN',
  BLUR: 'BLUR',
  GRAYSCALE: 'GRAYSCALE',
  SHRINK: 'SHRINK',
  INVERT: 'INVERT',
  COLOR_IN: 'COLOR_IN',
  DARKENED: 'DARKENED',
};

export const IMAGE_LOADING_MODE = {
  BLUR: 'BLUR',
  COLOR: 'COLOR',
  MAIN_COLOR: 'MAIN_COLOR',
};

export const SCROLL_ANIMATION = {
  NO_EFFECT: 'NO_EFFECT',
  FADE_IN: 'FADE_IN',
  GRAYSCALE: 'GRAYSCALE',
  SLIDE_UP: 'SLIDE_UP',
  EXPAND: 'EXPAND',
  SHRINK: 'SHRINK',
  ZOOM_OUT: 'ZOOM_OUT',
  ONE_COLOR: 'ONE_COLOR',
  MAIN_COLOR: 'MAIN_COLOR',
  BLUR: 'BLUR',
};

export const CUBE_TYPE = {
  FILL: 'fill',
  FIT: 'fit',
  MIN: 'min',
  MAX: 'max',
};

export const TEXT_PLACEMENT = {
  SHOW_ON_HOVER: 'SHOW_ON_HOVER',
  SHOW_BELOW: 'SHOW_BELOW',
  SHOW_ABOVE: 'SHOW_ABOVE',
  SHOW_ON_THE_RIGHT: 'SHOW_ON_THE_RIGHT',
  SHOW_ON_THE_LEFT: 'SHOW_ON_THE_LEFT',
};

export const CALCULATE_TEXT_BOX_HEIGHT = {
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL',
};

export const TEXT_BOX_WIDTH_CALCULATION_OPTIONS = {
  MANUAL: 'MANUAL',
  PERCENT: 'PERCENT',
};
