import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../hoc/with-translate';

const Counter = ({ t, count, i18nKey, className, showZero, ariaHidden }) =>
  (count || showZero) && (
    <span aria-hidden={ariaHidden} className={className}>
      {t(i18nKey, { count })}
    </span>
  );

Counter.propTypes = {
  count: PropTypes.number,
  i18nKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  showZero: PropTypes.bool,
  t: PropTypes.func,
  ariaHidden: PropTypes.bool,
};

Counter.defaultProps = {
  count: 0,
  showZero: true,
  i18nKey: 'counter.count',
};

export default withTranslate(Counter);
