import React from 'react';

export const ArrowRightIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="m 5.4964265,6.0000035 -5.430265,5.1757985 c -0.08821,0.08407 -0.08821,0.220384 0,0.304458 l 0.479141,0.456688 c 0.08821,0.08407 0.23122,0.08407 0.319427,0 l 6.06912,-5.7847155 c 0.044104,-0.042037 0.066156,-0.097133 0.066156,-0.152229 0,-0.0551 -0.02205,-0.110193 -0.06616,-0.15223 l -6.06912,-5.784715 c -0.08821,-0.08407 -0.23122,-0.08407 -0.319427,0 l -0.479141,0.456688 c -0.08821,0.08407 -0.08821,0.220385 0,0.304459 z" />
  </svg>
);
