import { reduce, toString } from 'lodash';
import { extractHashtagsFromText } from './hashtag-utils';

export const shouldSearchByHashtag = query => {
  const hashtags = extractHashtagsFromText(query);
  return (
    query &&
    !reduce(hashtags, (prevValue, hashTag) => prevValue.replace(`#${hashTag}`, ''), toString(query)).replace(
      /\s|,/g,
      '',
    )
  );
};
