import { get } from 'lodash';
import { connect } from '../../common/components/runtime-context';

import { getLastPage } from '../../common/services/pagination';
import {
  getCurrentPage,
  getEntityCount,
  getLastPageIndex,
  getPaginationPageSize,
  getPaginationType,
} from '../../common/selectors/pagination-selectors';
import { PAGINATION_TYPE_MULTIPLE_PAGES } from '@wix/communities-blog-client-common/dist/src/constants/pagination';

const mapRuntimeToProps = section => (state, ownProps) => {
  const paginationType = getPaginationType(state, section);

  const page =
    paginationType === PAGINATION_TYPE_MULTIPLE_PAGES
      ? Number(get(ownProps, 'params.page', getCurrentPage(state, 'posts')))
      : getLastPageIndex(state, 'posts');

  const pageSize = getPaginationPageSize(state, section);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);
  return {
    paginationType,
    page,
    pageSize,
    lastPage,
    isValidPage: page === 1 || (page > 1 && page <= lastPage),
    entityCount,
    showPagination: paginationType === PAGINATION_TYPE_MULTIPLE_PAGES,
  };
};

export const getPaginationSettings = (section, state) => mapRuntimeToProps(section)(state);

export default function withPaginationSettings(section) {
  return WrappedComponent => connect(mapRuntimeToProps(section))(WrappedComponent);
}
