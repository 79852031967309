import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import Pagination from '../pagination';
import styles from './pagination-regular.scss';

const PaginationRegular = ({ showPosition, pageClass, positionClass, arrowFillClass, ...props }) => (
  <Pagination
    className={classNames(styles.container, showPosition && styles.withPosition)}
    arrowClass={styles.arrow}
    innerClass={classNames(styles.page, pageClass)}
    activeClass={classNames(styles.active, 'blog-button-color')}
    disabledClass={styles.disabled}
    positionClass={classNames(styles.position, positionClass)}
    showPosition={showPosition}
    arrowLeft={<ArrowLeftIcon className={arrowFillClass} />}
    arrowRight={<ArrowRightIcon className={arrowFillClass} />}
    {...props}
  />
);

PaginationRegular.propTypes = {
  showPosition: PropTypes.bool,
  arrowFillClass: PropTypes.string,
  pageClass: PropTypes.string,
  positionClass: PropTypes.string,
};

PaginationRegular.defaultProps = {
  arrowFillClass: 'blog-icon-fill',
};

export default PaginationRegular;
