import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Link from '../components/link/internal-link';
import getDisplayName from '../services/get-display-name';

export default function withConditionalLink(Component) {
  const Wrapper = ({ link, ...props }) =>
    get(link, 'to') ? (
      <Link {...link}>
        <Component {...props} />
      </Link>
    ) : (
      <Component {...props} />
    );

  Wrapper.displayName = `withConditionalLink(${getDisplayName(Component)})`;

  Wrapper.propTypes = {
    link: PropTypes.object,
  };

  hoistNonReactStatics(Wrapper, Component);

  return Wrapper;
}
