import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../../common/components/link/internal-link';
import withTranslate from '../../../common/hoc/with-translate';
import styles from './write-comment.scss';

const WriteComment = ({ to, className, t, addHoverClasses }) =>
  to ? (
    <Link to={to} className={className} addHoverClasses={addHoverClasses}>
      <span data-hook="write-comment__label" className={styles.label}>
        {t('write-comment.write-a-comment')}
      </span>
    </Link>
  ) : null;

WriteComment.propTypes = {
  addHoverClasses: PropTypes.bool,
  to: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func,
};

export default flowRight(withTranslate)(WriteComment);
