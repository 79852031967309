import { isSSR } from './is-ssr';
import { getImageUrl as _getImageUrl } from '@wix/communities-blog-universal/dist/src/utils/media/image';
import { resizeThumbnail as _resizeThumbnail } from '@wix/communities-blog-universal/dist/src/utils/media/thumbnail';

export { getImageFilename } from '@wix/communities-blog-universal/dist/src/utils/media/image';

let _imageHost = '';
export const initImageUtils = imageHost => (_imageHost = imageHost);

const WIDTHS_MOBILE = [320, 375, 425, 768];
export const screenWidth = isSSR() ? 980 : window.screen.width;
export const screenPixelRatio = isSSR() ? 1 : window.devicePixelRatio;
export const getFormat = isSSR => (!isSSR && window.__IS_WEBP_SUPPORTED__ ? 'webp' : 'png');

const snapToClosestScreenWidth = w =>
  WIDTHS_MOBILE.reduce((closest, v) => {
    const d1 = v - w;
    const d2 = closest - w;

    return Math.abs(d1) < Math.abs(d2) ? (d1 >= 0 || d2 < 0 ? v : closest) : closest;
  });
const getProportionalHeight = (originalWidth, originalHeight, w) => w / (originalWidth / originalHeight);
const getProportionalWidth = (originalWidth, originalHeight, h) => h * (originalWidth / originalHeight);
// eslint-disable-next-line max-params
const adjustSizeByPixelRatio = (originalWidth, originalHeight, w, h, pixelRatio) => {
  const nW = Math.ceil(w * pixelRatio);
  const nH = Math.ceil(h * pixelRatio);
  const isLargerThanOrig = nW > originalWidth || nH > originalHeight;

  return [isLargerThanOrig ? originalWidth : nW, isLargerThanOrig ? originalHeight : nH];
};

/**
 * Returns filled [width, height] of an image that is best for current device based on given params.
 * @param oW - original image width
 * @param oH - original image height
 * @param w - new image width (the size that image will be shown)
 * @param h - new image height (the size that image will be shown)
 * @param isMobile
 * @param sW - screenWidth, injected for testing, on live taken from window
 * @param pR - pixelRatio, injected for testing, on live taken from window
 * @returns [width, height]
 */
// eslint-disable-next-line max-params
export const getFillSize = (oW, oH, w, h, isMobile, sW = screenWidth, pR = screenPixelRatio) => {
  if (isMobile && (!w || w > sW)) {
    w = snapToClosestScreenWidth(sW);
  }

  if (w >= oW || h >= oH || !(w || h)) {
    return [oW, oH];
  } else if (w && h) {
    const propW = getProportionalWidth(oW, oH, h);
    const propH = getProportionalHeight(oW, oH, w);
    const scaleByW = propH >= h;
    return adjustSizeByPixelRatio(oW, oH, scaleByW ? w : propW, scaleByW ? propH : h, pR);
  } else if (w) {
    return adjustSizeByPixelRatio(oW, oH, w, getProportionalHeight(oW, oH, w), pR);
  } else if (h) {
    return adjustSizeByPixelRatio(oW, oH, getProportionalWidth(oW, oH, h), h, pR);
  }
};

export const getImageUrl = (image, maxWidth, maxHeight, quality, type, format, blur) =>
  _getImageUrl(_imageHost)(image, { maxWidth, maxHeight, quality, type, format, blur });

export const resizeThumbnail = (pathname, width, height) => _resizeThumbnail(_imageHost)(pathname, width, height);
