import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostStats from '../post-stats';
import withFontClassName from '../../hoc/with-font-class-name';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import styles from './post-footer-common.scss';

export class PostFooterCommon extends Component {
  handleLikeClick = () => {
    const { onLikeClick, post } = this.props;
    onLikeClick(post._id);
  };

  render() {
    const {
      post,
      postLink,
      displayIcons,
      contentFontClassName,
      commentClassName,
      textColorClassName,
      type,
      forPublicUser,
      showViewCount,
      showCommentCount,
      showLikeCount,
      className,
      LikeButton,
    } = this.props;

    return (
      <div
        className={classNames(
          styles.container,
          styles[type],
          contentFontClassName,
          textColorClassName,
          'post-footer',
          className,
        )}
      >
        <div className={styles.leftButtons}>
          <PostStats
            commentClassName={commentClassName}
            post={post}
            displayIcons={displayIcons}
            postLink={postLink}
            showViewCount={showViewCount}
            showCommentCount={showCommentCount}
          />
        </div>
        {showLikeCount && (
          <div className={styles.rightButtons}>
            <LikeButton
              onClick={forPublicUser(this.handleLikeClick)}
              entity={post}
              className={'post-footer__like-button'}
            />
          </div>
        )}
      </div>
    );
  }
}

export const PostFooterProps = {
  post: PropTypes.object.isRequired,
  postLink: PropTypes.string.isRequired,
  displayIcons: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  textColorClassName: PropTypes.string,
  commentClassName: PropTypes.string,
  type: PropTypes.string,
  forPublicUser: PropTypes.func,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  className: PropTypes.string,
};

PostFooterCommon.propTypes = {
  ...PostFooterProps,
  LikeButton: PropTypes.func,
};

PostFooterCommon.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
  showLikeCount: true,
};

// prettier-ignore
export default flowRight(
  withFontClassName,
  withLayoutColorClasses,
  withAuth,
  withFeedMetadataSettings,
)(PostFooterCommon);
