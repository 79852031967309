import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import { SearchIcon } from '../icons/search-icon';
import ClearIcon from './clear-icon';
import withTranslate from '../../hoc/with-translate';
import { handleEnterKeyUp } from '../../services/accessibility';
import styles from './search-input.scss';
import { encodeQuery } from '../../../search/services/query-encoding';

const CLEAR_ANIMATION_TIMEOUT = 500;

export class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      isExpanded: props.isExpanded,
    };

    this.setInput = this.setInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  setInput(node) {
    this.input = node;
  }

  isExpandable() {
    return !this.props.isExpanded;
  }

  expand() {
    this.setState({ isExpanded: true });
    this.props.headerSearchOpened();
  }

  collapse() {
    this.setState({ isExpanded: false });
    this.props.headerSearchClosed();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isExpanded && this.state.isExpanded) {
      this.input.focus();
    }
  }

  handleChange(e) {
    this.setValue(e.target.value);
  }

  handleClear() {
    this.setValue('');
    if (this.isExpandable()) {
      this.collapse();
    }
  }

  handleClick() {
    if (this.isExpandable() && !this.state.isExpanded) {
      this.expand();
    } else if (this.props.onClick) {
      this.props.onClick(this.state.value);
    }
  }

  handleKeyUp(event) {
    if (this.props.onClick && event.keyCode === 13) {
      this.props.onClick(this.state.value);
    } else if (event.keyCode === 27) {
      event.target.blur();
      this.handleClear();
    }
  }

  setValue(value) {
    this.setState({ value }, () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(this.state.value);
      }
    });
  }

  render() {
    const { type, className, inputClassName, iconClassName, borderClassName, color, t, isFullWidth } = this.props;
    const { isExpanded, value } = this.state;
    const showClearButton = (this.isExpandable() && isExpanded) || (!this.isExpandable() && value);
    const containerClassName = classNames(styles.container, className, 'search-input', {
      [styles[type]]: type,
      [styles.expanded]: isExpanded,
      [styles.isFullWidth]: isFullWidth,
    });

    return (
      <div className={containerClassName} tabIndex="-1">
        <div
          tabIndex={!isExpanded ? '0' : undefined}
          role="button"
          aria-label={t('search-input.placeholder')}
          onKeyUp={handleEnterKeyUp(this.handleClick)}
          onClick={this.handleClick}
        >
          <SearchIcon className={classNames(styles.icon, iconClassName)} fill={color} />
        </div>
        <input
          ref={this.setInput}
          className={classNames(styles.input, inputClassName, 'search-input__input')}
          onChange={this.handleChange}
          value={value}
          type="text"
          placeholder={t('search-input.placeholder')}
          onKeyUp={this.handleKeyUp}
          style={{ color }}
        />
        <div className={classNames(styles.spacer, inputClassName, styles.placeholder)}>
          {t('search-input.placeholder')}
        </div>
        <div className={classNames(styles.border, borderClassName)} style={{ backgroundColor: color }} />
        <CSSTransitionGroup
          transitionName="clear"
          transitionEnterTimeout={CLEAR_ANIMATION_TIMEOUT}
          transitionLeaveTimeout={CLEAR_ANIMATION_TIMEOUT}
        >
          {showClearButton ? (
            <button
              className={classNames(styles.clear, 'search-input__clear-button')}
              onClick={this.handleClear}
              tabIndex="-1"
            >
              <ClearIcon className={classNames(styles.clearIcon, iconClassName)} fill={color} />
            </button>
          ) : null}
        </CSSTransitionGroup>
      </div>
    );
  }
}

SearchInput.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  isExpanded: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  borderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.string,
  headerSearchOpened: PropTypes.func,
  headerSearchClosed: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  headerSearchOpened: actions.headerSearchOpened,
  headerSearchClosed: actions.headerSearchClosed,
  onClick: query => actions.navigateWithinBlog(`/search/${encodeQuery(query)}`),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(SearchInput);
