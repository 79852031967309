import * as imageSdk from 'image-client-api/dist/imageClientSDK';

const WIX_MEDIA_PREFIX = 'https://static.wixstatic.com/media/';
const WIX_MEDIA_DOMAIN = '//static.wixstatic.com';

const isExternalUrl = url => {
  return /(^https?)|(^data)|(^blob)/.test(url);
};

const useImageClientApi = (item, sharpParams, requiredWidth) => {
  const isImageSizeAvailable = !item.isDimensionless;
  const isThumb = sharpParams && sharpParams.blur > 0;
  const isPixelImage = requiredWidth === 1;
  return !isPixelImage && isImageSizeAvailable && !isThumb;
};

const isWixMediaUrl = url => url.indexOf(WIX_MEDIA_DOMAIN) >= 0;

const getWixFilename = url => url.replace(WIX_MEDIA_PREFIX, '');

const prefixUrlIfNeeded = originalUrl => {
  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else {
    return WIX_MEDIA_PREFIX + originalUrl;
  }
};

const getDevicePixelRatio = item => {
  try {
    if (useImageClientApi(item)) {
      return 1;
    } else {
      return window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI; // Support for IE10
    }
  } catch (e) {
    return 1;
  }
};

const resizeVideoImp = (item, originalUrl, requiredWidth, requiredHeight) => {
  let videoUrl = originalUrl;

  if (item.qualities && item.qualities.length) {
    let suffix = '/';

    const mp4Qualities = item.qualities.filter(video => video.formats[0] === 'mp4');
    // search for the first quality bigger that the required one
    for (let quality, q = 0; (quality = mp4Qualities[q]); q++) {
      if (quality.height >= requiredHeight || !mp4Qualities[q + 1]) {
        suffix += quality.quality; // e.g. 720p
        for (let format, i = 0; (format = quality.formats[i]); i++) {
          videoUrl = '//video.wixstatic.com/video/' + item.url + suffix + '/' + format + '/file.' + format;
        }
        break;
      }
    }

    return videoUrl;
    // const wixQualities = this.qualities.filter(video => video.formats[0] === 'wix');
    // urls.wix = window.location.protocol + '//video.wixstatic.com/video/' + this.url + suffix + '/' + format + '/file.' + format;
  }
};

const resizeUrlImp_manual = (
  item,
  originalUrl,
  resizeMethod,
  requiredWidth,
  requiredHeight,
  sharpParams,
  focalPoint,
) => {
  requiredWidth = Math.ceil(requiredWidth);
  requiredHeight = Math.ceil(requiredHeight);

  const requiredRatio = requiredWidth / requiredHeight;

  // assign sharp default parameters
  sharpParams = sharpParams || {};

  // calc default quality
  if (!sharpParams.quality) {
    sharpParams.quality = 90;
  }

  // don't allow quality above 90 till we have proper UI indication
  sharpParams.quality = Math.min(90, sharpParams.quality);

  if (sharpParams.allowUsm === true) {
    sharpParams.usm.usm_a = Math.min(5, Math.max(0, sharpParams.usm.usm_a || 0));
    sharpParams.usm.usm_r = Math.min(128, Math.max(0, sharpParams.usm.usm_r || 0)); // should be max 500 - but it's returning a 404
    sharpParams.usm.usm_t = Math.min(1, Math.max(0, sharpParams.usm.usm_t || 0));
  }

  const focalPointObj = { x: 50, y: 50 };
  if (focalPoint && focalPoint[0] >= 0 && focalPoint[1] >= 0) {
    focalPointObj.x = Math.round(focalPoint[0] * 100);
    focalPointObj.y = Math.round(focalPoint[1] * 100);
  }

  if (isExternalUrl(originalUrl)) {
    return originalUrl;
  } else if (!focalPoint) {
    // todo remove when supporting focal point
    let retUrl = prefixUrlIfNeeded(originalUrl) + '/v1/' + resizeMethod + '/';
    retUrl += 'w_' + requiredWidth;
    retUrl += ',h_' + requiredHeight;
    if (resizeMethod === 'fill') {
      retUrl += `,fp_0.${focalPointObj.x}_0.${focalPointObj.y}`;
    }
    // retUrl += ',al_' + (faces ? 'fs' : 'c');
    retUrl += ',q_' + sharpParams.quality;
    if (sharpParams.blur) {
      retUrl += ',blur_' + sharpParams.blur;
    }

    retUrl +=
      sharpParams.usm && sharpParams.usm.usm_r
        ? ',usm_' +
          sharpParams.usm.usm_r.toFixed(2) +
          '_' +
          sharpParams.usm.usm_a.toFixed(2) +
          '_' +
          sharpParams.usm.usm_t.toFixed(2)
        : '';
    // Important to use this as the last param
    retUrl += '/' + originalUrl;
    return retUrl;
  } else {
    let scale;
    let x;
    let y;
    let orgW;
    let orgH;

    // find the scale
    if (item.ratio > requiredRatio) {
      // wide image (relative to required ratio
      scale = requiredHeight / item.maxHeight;
      orgW = Math.floor(requiredHeight * item.ratio);
      y = 0;
      x = Math.round(orgW * focalPoint[0] - requiredWidth / 2);
      x = Math.min(orgW - requiredWidth, x);
      x = Math.max(0, x);
    } else {
      // narrow image

      scale = requiredWidth / item.maxWidth;
      orgH = Math.floor(requiredWidth / item.ratio);
      x = 0;
      y = Math.round(orgH * focalPoint[1] - requiredHeight / 2);
      y = Math.min(orgH - requiredHeight, y);
      y = Math.max(0, y);
    }

    // make sure scale is not lower than needed
    // scale must be higher to prevent cases that there will be white margins (or 404)
    scale = Math.ceil(scale * 100) / 100;

    let retUrl = prefixUrlIfNeeded(originalUrl) + '/v1/crop/';
    retUrl += 'w_' + requiredWidth;
    retUrl += ',h_' + requiredHeight;
    retUrl += ',x_' + x;
    retUrl += ',y_' + y;
    retUrl += ',scl_' + scale.toFixed(2);
    retUrl += ',q_' + sharpParams.quality;
    if (sharpParams.blur) {
      retUrl += ',blur_' + sharpParams.blur;
    }
    retUrl +=
      sharpParams.usm && sharpParams.usm.usm_r
        ? ',usm_' +
          sharpParams.usm.usm_r.toFixed(2) +
          '_' +
          sharpParams.usm.usm_a.toFixed(2) +
          '_' +
          sharpParams.usm.usm_t.toFixed(2)
        : '';
    // Important to use this as the last param
    retUrl += '/' + originalUrl;
    return retUrl;
  }
};

const resizeUrlImp_sdk = (item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint) => {
  // assign default parameters
  originalUrl = originalUrl || '';
  sharpParams = sharpParams || {};

  // calc default quality
  if (sharpParams.quality > 0) {
    // don't allow quality above 90 till we have proper UI indication
    sharpParams.quality = Math.min(90, sharpParams.quality);
  }

  const focalPointObj = { x: 50, y: 50 };
  if (focalPoint && focalPoint[0] >= 0 && focalPoint[1] >= 0) {
    focalPointObj.x = Math.round(focalPoint[0] * 100);
    focalPointObj.y = Math.round(focalPoint[1] * 100);
  }

  if (sharpParams.allowUsm === true && sharpParams.usm) {
    sharpParams.usm.usm_a = Math.min(5, Math.max(0, sharpParams.usm.usm_a || 0));
    sharpParams.usm.usm_r = Math.min(128, Math.max(0, sharpParams.usm.usm_r || 0)); // should be max 500 - but it's returning a 404
    sharpParams.usm.usm_t = Math.min(1, Math.max(0, sharpParams.usm.usm_t || 0));
  } else {
    sharpParams.usm = {
      usm_a: 0,
      usm_r: 0,
      usm_t: 0,
    };
  }

  if (isExternalUrl(originalUrl) && !isWixMediaUrl(originalUrl)) {
    return originalUrl;
  } else {
    let resizer = () => {};
    if (resizeMethod === 'fit') {
      // function getScaleToFitImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
      resizer = imageSdk.getScaleToFitImageURL;
    } else {
      // function getScaleToFillImageURL(relativeUrl, sourceWidth, sourceHeight, targetWidth, targetHeight, options) {
      resizer = imageSdk.getScaleToFillImageURL;
    }

    /**
     * the transform options
     * @typedef  {object}   ImageTransformOptions
     * @property {boolean}  [progressive]               image transform progressive
     * @property {number}   [quality]                   image transform quality
     * @property {string}   [watermark]                 image watermark id
     * @property {object}   [unsharpMask]               unsharpMask filter
     * @property {number}   [unsharpMask.radius]        unsharpMask radius
     * @property {number}   [unsharpMask.amount]        unsharpMask amount
     * @property {number}   [unsharpMask.threshold]     unsharpMask threshold
     */

    const options = {};
    if (sharpParams.quality > 0) {
      options.quality = sharpParams.quality;
    }
    if (sharpParams.blur > 0) {
      options.filters = {
        blur: sharpParams.blur,
      };
    }
    if (focalPointObj) {
      options.focalPoint = focalPointObj;
    }
    if (sharpParams && sharpParams.usm) {
      options.unsharpMask = {
        radius: parseFloat(sharpParams.usm.usm_r),
        amount: parseFloat(sharpParams.usm.usm_a),
        threshold: parseFloat(sharpParams.usm.usm_t),
      };
    }

    const retUrl = resizer(
      getWixFilename(originalUrl),
      item.maxWidth,
      item.maxHeight,
      requiredWidth,
      requiredHeight,
      options,
    );

    return retUrl;
  }
};

const resizeUrlImp = (item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint) => {
  requiredWidth = Math.ceil(requiredWidth * getDevicePixelRatio(item));
  requiredHeight = Math.ceil(requiredHeight * getDevicePixelRatio(item));

  // remove resizing parameters if exists
  const resizingParamerterRegex = /\/v\d\/(fill|fit)\/w_\d*,h_\d*/;
  const resizingParametersPosition = resizingParamerterRegex.exec(originalUrl);
  if (resizingParametersPosition && resizingParametersPosition.index > 0) {
    originalUrl = originalUrl.substr(0, resizingParametersPosition.index);
  }

  if (resizeMethod === 'video') {
    return resizeVideoImp(item, originalUrl, requiredWidth, requiredHeight);
  } else if (resizeMethod === 'full') {
    return prefixUrlIfNeeded(originalUrl);
  } else if (useImageClientApi(item, sharpParams, requiredWidth)) {
    return resizeUrlImp_sdk(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint);
  } else {
    return resizeUrlImp_manual(item, originalUrl, resizeMethod, requiredWidth, requiredHeight, sharpParams, focalPoint);
  }
};

const resizeMediaUrl = resizeUrlImp;

export { resizeMediaUrl };
