import { get, flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';
import withLayoutProps from './with-layout-props';
import { getCardBorderWidthPath } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { isLayoutTextOnImage } from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { getAppSettings } from '../selectors/app-settings-base-selectors';

const DEFAULT_WIDTH = 1;

const getBorderWidth = (state, layoutType, section) => {
  if (isLayoutTextOnImage(layoutType)) {
    return 0;
  }

  const stylePath = getCardBorderWidthPath(layoutType, section);
  return get(getAppSettings(state), stylePath, DEFAULT_WIDTH);
};

const mapRuntimeToProps = (state, { layoutType, section }) => ({
  borderWidth: getBorderWidth(state, layoutType, section),
});

// prettier-ignore
export default flowRight(
  withLayoutProps(),
  connect(mapRuntimeToProps),
  withHocName('WithCardBorderWidth')
);
