import URI from 'urijs';
import { reduce, endsWith } from 'lodash';

export const getCategoryUrl = (sectionUrl, path, slug) => {
  if (sectionUrl) {
    const segments = [path, slug];
    if (endsWith(slug, '/')) {
      segments.push('');
    }

    return reduce(segments, (url, segment) => url.segment(segment), new URI(sectionUrl)).toString();
  }

  return '';
};
