import { mapValues, get } from 'lodash';
import { connect } from '../components/runtime-context';
import { getAppSettings } from '../selectors/app-settings-base-selectors';
import { getSiteColors } from '../selectors/app-settings-selectors';

const getSettings = (state, config) => mapValues(config, (path, prop) => get(getAppSettings(state), path || prop));

const mapRuntimeToProps = config => state => ({
  ...getSettings(state, config),
  siteColors: getSiteColors(state),
});

export default function withAppSettings(config) {
  return WrappedComponent => connect(mapRuntimeToProps(config))(WrappedComponent);
}
