import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from '../runtime-context';
import MoreIcon from '../icons/more-icon';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../hoc/with-translate';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { handleEnterKeyUp, handleEscapeKeyUp } from '../../services/accessibility';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import styles from './more-button.scss';

const BOTTOM_THRESHOLD = 60;

export class MoreButton extends Component {
  state = {
    isVisible: false,
  };

  handleClick = () => {
    if (this.state.isVisible) {
      this.hideComponent();
      return;
    }

    this.showComponent();
  };

  setContainer = element => (this.container = element);

  setActionsContainer = element => (this.actionsContainer = element);

  checkIfFitsInEditor = () => {
    const rect = this.container.getBoundingClientRect();
    const scrollOffset = window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0;
    const bodyHeight = document.body.offsetHeight;
    const topOffset = scrollOffset + rect.top;
    const bottomOffset = bodyHeight - topOffset - rect.height;
    const actionsHeight = this.actionsContainer ? this.actionsContainer.offsetHeight : 0;
    this.setState({
      isAtTop: actionsHeight > bottomOffset - BOTTOM_THRESHOLD && actionsHeight < topOffset,
    });
  };

  checkIfFits = () => {
    const rootRect = document.getElementById(this.props.componentId).getBoundingClientRect();
    const moreButtonRect = this.container.getBoundingClientRect();
    const actionsHeight = this.actionsContainer ? this.actionsContainer.offsetHeight : 0;

    const availableSpaceAtTop = moreButtonRect.top - Math.max(rootRect.top, 0);
    const availableSpaceAtBottom =
      window.innerHeight > rootRect.bottom
        ? rootRect.bottom - Math.max(moreButtonRect.bottom, 0)
        : window.innerHeight - Math.max(moreButtonRect.bottom, 0);

    this.setState({
      isAtTop: availableSpaceAtTop > actionsHeight && availableSpaceAtBottom < actionsHeight,
    });
  };

  showComponent = () => {
    document.addEventListener('click', this.hideComponent);
    this.setState(
      {
        isVisible: true,
      },
      this.props.isSite ? this.checkIfFits : this.checkIfFitsInEditor,
    );
  };

  hideComponent = () => {
    document.removeEventListener('click', this.hideComponent);
    this.setState({
      isVisible: false,
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.hideComponent);
  };

  renderActionsContainer = () => {
    const { contentFontClassName, cardBackgroundColor } = this.props;
    const actionsContainerClass = classNames(
      styles.actions,
      contentFontClassName,
      'blog-text-color',
      'blog-default-card-background-color',
      { [styles.actionsTop]: this.state.isAtTop },
    );
    const actionsContainerStyle = {
      backgroundColor: cardBackgroundColor,
    };

    return (
      <div
        ref={this.setActionsContainer}
        className={actionsContainerClass}
        style={actionsContainerStyle}
        role="menu"
        data-hook="actions"
      >
        {this.props.children}
      </div>
    );
  };

  render = () => {
    const { id, icon, t, isWhite, className: buttonClassName } = this.props;
    const className = classNames(buttonClassName, styles.more, 'more-button', {
      [styles.moreWhite]: isWhite,
    });

    return (
      <div
        onClick={this.handleClick}
        className={className}
        ref={this.setContainer}
        onKeyUp={handleEscapeKeyUp(this.hideComponent)}
      >
        <button
          type="button"
          className={styles.icon}
          tabIndex="0"
          aria-pressed={this.state.isVisible}
          aria-label={t('more-button.more-actions')}
          onKeyPress={handleEnterKeyUp(this.handleClick)}
          id={id}
          data-hook="more-button"
        >
          {icon || (
            <MoreIcon
              className={classNames(this.props.iconColorClassName, 'blog-post-homepage-link-hashtag-hover-fill')}
            />
          )}
        </button>
        {this.state.isVisible ? this.renderActionsContainer() : null}
      </div>
    );
  };
}

MoreButton.propTypes = {
  cardBackgroundColor: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  isWhite: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.node,
  t: PropTypes.func.isRequired,
  iconColorClassName: PropTypes.string.isRequired,
  componentId: PropTypes.string,
  isSite: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isSite: isSite(state),
  componentId: host.id,
});

export default flowRight(
  withCardBackgroundColor,
  withTranslate,
  withFontClassName,
  withLayoutColorClasses,
  connect(mapRuntimeToProps),
)(MoreButton);
