import { get, isEmpty } from 'lodash';
import { NAME } from './modal-state-name';

const getModalState = state => state[NAME];
export const getResolvedModals = state => getModalState(state).resolvedModalList;

export const getModal = (state, id) => getModalState(state).openedModals[id];
export const getModalMeta = (state, id) => get(getModal(state, id), 'meta', {});
export const getOpenedModals = state => getModalState(state).openedModals;
export const isModalResolved = (state, id) => Boolean(getResolvedModals(state).find(t => t.type === id));
export const getIsModalDisplayed = (state, id) => !isEmpty(id ? getOpenedModals(state) : getModal(state, id));
