import { isObject, isString } from 'lodash';
import uri from 'urijs';
import { getImageUrl } from './image-utils';

export default function resolveUserImage(image, { type, width = 100, height = 100, quality, format, blur } = {}) {
  if (isObject(image)) {
    return getImageUrl(image, width, height, quality, type, format, blur);
  }

  if (isString(image) && uri(image).is('relative')) {
    return getImageUrl({ file_name: image }, width, height, quality, type, format, blur);
  }

  return image;
}
