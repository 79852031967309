import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CounterCompact from '../counter-compact';
import { ViewCountIcon } from '../icons/view-count-icon';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withTranslate from '../../hoc/with-translate';

const ViewCountCompact = ({ t, iconColorClassName, ...counterProps }) => (
  <CounterCompact {...counterProps} dataHook="view-count-compact">
    <ViewCountIcon aria-label={t('view-count.icon-aria-label')} className={iconColorClassName} />
  </CounterCompact>
);

ViewCountCompact.propTypes = {
  iconColorClassName: PropTypes.string,
  t: PropTypes.func,
};
export default flowRight(withTranslate, withLayoutColorClasses)(ViewCountCompact);
